import React from 'react';
import { slide as Menu } from 'react-burger-menu'

var styles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '36px',
      height: '30px',
      right: '36px',
      top: '36px',
      boxShadow: "none"
    },
    
    bmBurgerBars: {
      background: '#373a47'
    },
    bmBurgerBarsHover: {
      background: '#a90000'
    },
    bmCrossButton: {
      height: '24px',
      width: '24px'
    },
    bmCross: {
      background: '#bdc3c7',
      boxShadow: "none"
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%'
    },
    bmMenu: {
      background: '#373a47',
      padding: '2.5em 1.5em 0',
      fontSize: '1.15em'
    },
    bmMorphShape: {
      fill: '#373a47'
    },
    bmItemList: {
      color: '#b8b7ad',
      padding: '0.8em'
    },
    bmItem: {
      display: 'block',
      fontWeight: 100,
      color: "white"
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)'
    }
  }

export default  (props) => 
    <div id="main-nav" className={`nav-color-${props.color}`}>       
        <Menu styles={styles} burgerButtonClassName="mobileNav bm-overlay" right>
            <a className="menu-item" href="/#home">HOME</a>
            <a className="menu-item" href="#one">ABOUT</a>
            <a className="menu-item" href="#two">SERVICES</a>
            <a className="menu-item" href="#footer">SCHEDULE AN APPOINTMENT</a>
            <a className="menu-item" href="/Podcast">PODCAST</a>
            <a className="menu-item" href="tel:2489068547">248.906.8547</a>
        </Menu>

        <nav className="fullNav" > 
            <ul>
                <li><a href="/#home">HOME</a></li>
                <li><a href="/#one">ABOUT</a></li>
                <li><a href="/#two">SERVICES</a></li>
                <li><a href="/#footer">SCHEDULE AN APPOINTMENT</a></li>
                <li><a href="/Podcast">PODCAST</a></li>
                <li><a href="tel:2489068547">248.906.8547</a></li>
            </ul>
        </nav>

    </div>
